<template>
  <div id="app" :class="type == 0?(!showMenu?'hclosed html':'html'):(!showMenu?'hclosed':'')">
    <page-header :type="type"></page-header>
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view></router-view>
    </keep-alive>
    <router-view v-else/>
    <page-menu :type="type"></page-menu>
  </div>
</template>

<script>
  import PageHeader from "components/PageHeader"
  import PageMenu from "components/PageMenu"
  import {isMobile} from "@/utils/utils"
  import { mapGetters } from 'vuex'
  export default {
    name: 'App',
    components: {
      PageHeader,
      PageMenu
    },
    data() {
      return {
        type: 0
      }
    },
    created() {
      if((this.$route.path == '/login'
      || this.$route.path == '/findId'
      || this.$route.path == '/findPw')) {
        this.type = 1
      } else {
        this.type = 0
      }
    },
    computed: {
      ...mapGetters(['showMenu']),
    },
    watch:{
      $route(to,from){
        if((this.$route.path == '/login'
      || this.$route.path == '/findId'
      || this.$route.path == '/findPw')) {
          this.type = 1
        } else {
          this.type = 0
        }
      }
    },
  }
</script>

<style>
	@import "./assets/css/base.css";
  .html{padding: 80px 0 0 288px;transition: all 0.2s linear;}
  .hclosed{padding: 80px 0 0 120px;}

  @media (max-width: 768px) {
    .html{padding: 140px 0 0 0;}
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .html{padding: 80px 0 0 120px;}
  }
</style>
