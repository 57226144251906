import { getAction, postAction } from "./manage"
import { getActionApi, postActionApi } from "./manageApi"


export async function signup(data) {

}

export async function login(param) {
  return postActionApi("/user-management/login", param)
}

export function getUserInfo() {
  return getAction('/user/member/getUserInfo')
}
