<template>
  <div class="header" v-if="type === 0" :class="!showMenu?'closed':''">
    <div class="div">
      <i class="menu el-icon-s-operation" @click="changeMenuShow"></i>
      <h1>{{menuTitle}}</h1>
    </div>
    <div class="div">
      <div class="borderInput">
        <input placeholder="Search here"/>
        <i class="el-icon-search"></i>
      </div>
      <p>Account ID# <b>14608</b></p>
      <div class="userIcon">
        <i class="el-icon-user-solid"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'PageHeader',
    props:{
      "type":{type:Number,default:0}
    },
    data() {
      return {
        menuTitle: ''
      }
    },
    computed: {
      ...mapGetters(['showMenu']),
    },
    watch: {
      '$route': {
        handler(newRoute) {
          if (!!newRoute.meta) {
            this.menuTitle = newRoute.meta.title
          }
        },
        immediate: true,
      }
    },
    methods: {
      changeMenuShow() {
        this.$store.commit('SET_SHOW_MENU', !this.showMenu);
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .header{height:80px;display: flex;z-index:100;transition: all 0.2s linear;align-items: center;top:0;left:288px;position: fixed;width:calc(100vw - 288px);background-color:#F8F9FA;padding:0 20px;justify-content: space-between;border-bottom: 1px solid #CBD5E0;}
  .header .div{display: flex;align-items: center;}
  .header p{color:#717D96;font-size:12px;}
  .header p b{font-size:14px;}
  .header .menu{font-size:30px;color:#4A5468;margin:0 30px 0 10px;cursor: pointer;}
  .header h1{color:#2D3648;font-size: 20px;font-weight: normal;}
  .borderInput{border:1px solid #EDF0F7;border-radius: 12px;overflow: hidden;background-color: #ffffff;margin-right:16px;}
  .borderInput input{border: none;width:100px;text-align: center;height:40px;font-size: 14px;margin-left:16px;}
  .borderInput i{font-size: 18px;margin: 0 10px;color: #718096;}
  .userIcon{background-color: #A0AEC0;border-radius: 50%;width: 32px;height:32px;display: flex;align-items: center;justify-content: center;color:#ffffff;margin-left: 16px;}
  .userIcon i{font-size: 20px;}
  .closed{left:120px;width:calc(100vw - 120px);}
  @media (max-width: 768px) {
    .header{flex-direction: column;padding:20px;left:0;height:140px;width:100vw;}
    .header .div{display: flex;justify-content: space-between;height:40px;width: 100%;}
    .header .div:nth-child(1){margin-bottom:20px;flex-direction: row-reverse;}
    .header .menu{font-size:30px;color:#4A5468;margin:0;}
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .header{left:120px;width:calc(100vw - 120px);}
  }
</style>
