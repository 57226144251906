<template>
  <div id="home">
    <div class="div">
      <div class="title">
        Monthly Analytics
        <span>All Download</span>
      </div>
      <div class="time">
        <span><i class="el-icon-arrow-left"></i></span>
        2024
        <span><i class="el-icon-arrow-right"></i></span>
      </div>
      <div id="basic_dashboard"></div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import * as echarts from 'echarts';
  export default {
    name: 'MonthlyAnalytics',
    components: {

    },
    data() {
      return {

      }
    },
    computed: {

    },
    mounted(){
      this.initEchart()
    },
    created(){

    },
    methods:{
      initEchart(){
        var chartDom = document.getElementById('basic_dashboard');
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#5856D6',
            '#F56565'
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            data: ['Total', 'Targeted'],
            bottom:'2%'
          },
          grid: {
            left: '4%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Total',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(88, 86, 214, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(88, 86, 214, 0)'
                  }
                ])
              },
              data: [120, 132, 101, 134, 90, 230, 210]
            },
            {
              name: 'Targeted',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(245, 101, 101, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(245, 101, 101, 0)'
                  }
                ])
              },
              data: [20, 20, 20, 20, 20, 20, 20]
            }
          ]
        };
        myChart.setOption(option);
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  #home .div{background-color: #ffffff;padding: 20px;display: flex;flex-direction: column;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  #home .div .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  #home .div .title span{height:32px;padding:0 20px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;font-size: 14px;display: flex;align-items: center;}
  #basic_dashboard{height:450px;}
  .time{display: flex;justify-content: flex-end;align-items: center;margin-top:30px;color:#5856D6;font-size: 14px;font-weight: bold;}
  .time span{border:1px solid #5856D6;height:20px;width:20px;border-radius: 5px;display: flex;justify-content: center;align-items: center;cursor: pointer;margin:0 14px;}
  .time span i{font-size:12px;font-weight: bold;}
  @media (max-width: 768px) {

  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {

  }
</style>
