<template>
  <div>
    <div class="type">
      <div>
        <span :class="type == 0 ? 'act' : ''" @click="type = 0">Chart</span>
        <span :class="type == 1 ? 'act' : ''" @click="type = 1">Map</span>
      </div>
    </div>
    <div class="home" v-show="type == 0">
      <div class="seo">
        <div class="title">
          SEO Anaytics Report
          <div class="time">
            <span><i class="el-icon-arrow-left"></i></span>
            2024
            <span><i class="el-icon-arrow-right"></i></span>
          </div>
        </div>
        <div class="img">
          <img src="../assets/imgs/logo.png">
          <div>
            <p>Displaying SEO Analytics Report for:</p>
            <p>August 2024</p>
            <b>Puche Dental Laboratory</b>
            <p>1617 Border Ave, Torrance, CA 90501, United States</p>
            <a>https://pachedantallabs.com/</a>
          </div>
        </div>
        <div class="list">
          <div class="item">
            <i class="el-icon-user"></i>
            <div>
              Unique Visitor
              <b>56</b>
            </div>
          </div>
          <div class="item">
            <i class="el-icon-view"></i>
            <div>
              Page Views
              <b>56</b>
            </div>
          </div>
          <div class="item">
            <i class="el-icon-upload2"></i>
            <div>
              Homepage Visitors
              <b>56</b>
            </div>
          </div>
          <div class="item">
            <i class="el-icon-star-off"></i>
            <div>
              Homepage Visitors
              <b>56</b>
            </div>
          </div>
        </div>
      </div>
      <div class="charts">
        <div class="item">
          <div id="chart1"></div>
        </div>
        <div class="item">
          <div id="chart2"></div>
        </div>
      </div>
      <div class="tables">
        <div class="item">
          <h1>Engagement</h1>
          <div class="table">
            <div class="header">
              <span>Source</span>
              <span>Sessions</span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
          </div>
          <p>Lorem ipsum dolor sit amet consectetur. Pharetra aliquam mollis imperdiet curabitur cras ipsum viverra. Adipiscing in morbi amet quis elit risus egestas nibh nunc. Nunc cursus eros tellus nulla at justo nulla. Pulvinar laoreet velit sed vestibulum quis nibh nullam lacus.</p>
        </div>
        <div class="item">
          <h1>Device Category</h1>
          <div class="table">
            <div class="header">
              <span>Source</span>
              <span>Sessions</span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
          </div>
          <p>Lorem ipsum dolor sit amet consectetur. Pharetra aliquam mollis imperdiet curabitur cras ipsum viverra. Adipiscing in morbi amet quis elit risus egestas nibh nunc. Nunc cursus eros tellus nulla at justo nulla. Pulvinar laoreet velit sed vestibulum quis nibh nullam lacus.</p>
        </div>
      </div>
      <div class="charts">
        <div class="item">
          <div id="chart3"></div>
        </div>
        <div class="item">
          <div id="chart4"></div>
        </div>
      </div>
      <div class="tables">
        <div class="item">
          <h1>Source Channels</h1>
          <div class="table">
            <div class="header">
              <span>Source</span>
              <span>Sessions</span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
          </div>
          <p>Lorem ipsum dolor sit amet consectetur. Pharetra aliquam mollis imperdiet curabitur cras ipsum viverra. Adipiscing in morbi amet quis elit risus egestas nibh nunc. Nunc cursus eros tellus nulla at justo nulla. Pulvinar laoreet velit sed vestibulum quis nibh nullam lacus.</p>
        </div>
        <div class="item">
          <h1>Top Pages</h1>
          <div class="table">
            <div class="header">
              <span>Source</span>
              <span>Sessions</span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
          </div>
          <p>Lorem ipsum dolor sit amet consectetur. Pharetra aliquam mollis imperdiet curabitur cras ipsum viverra. Adipiscing in morbi amet quis elit risus egestas nibh nunc. Nunc cursus eros tellus nulla at justo nulla. Pulvinar laoreet velit sed vestibulum quis nibh nullam lacus.</p>
        </div>
      </div>
      <div class="charts">
        <div class="item">
          <div id="chart5"></div>
        </div>
        <div class="item">
          <div id="chart6"></div>
        </div>
      </div>

      <div class="tables">
        <div class="item">
          <h1>Call / Website Clicks</h1>
          <div class="table">
            <div class="header">
              <span>Source</span>
              <span>Sessions</span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
          </div>
          <p>Lorem ipsum dolor sit amet consectetur. Pharetra aliquam mollis imperdiet curabitur cras ipsum viverra. Adipiscing in morbi amet quis elit risus egestas nibh nunc. Nunc cursus eros tellus nulla at justo nulla. Pulvinar laoreet velit sed vestibulum quis nibh nullam lacus.</p>
        </div>
        <div class="item">
          <h1>New v/s Returing Users</h1>
          <div class="table">
            <div class="header">
              <span>Source</span>
              <span>Sessions</span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
            <div class="row">
              <span>Renov Dental Group</span>
              <span>257 <a>2.4%</a></span>
            </div>
          </div>
          <p>Lorem ipsum dolor sit amet consectetur. Pharetra aliquam mollis imperdiet curabitur cras ipsum viverra. Adipiscing in morbi amet quis elit risus egestas nibh nunc. Nunc cursus eros tellus nulla at justo nulla. Pulvinar laoreet velit sed vestibulum quis nibh nullam lacus.</p>
        </div>
      </div>
    </div>
    <div class="home" v-show="type == 1">
      <div class="seo">
        <div class="title">
          SEO Places
          <div class="time">
            <span><i class="el-icon-arrow-left"></i></span>
            2024
            <span><i class="el-icon-arrow-right"></i></span>
          </div>
        </div>
        <div class="img" v-for="item,index in 5" :key="index">
          <img src="../assets/imgs/logo.png">
          <div>
            <h4>Keyword :<p>Dental lab in Torrance</p></h4>
            <h4>Previous Month :<p>2</p></h4>
            <h4>This Month :<p>2</p></h4>
          </div>
          <img class="map" src="../assets/imgs/map.png"/>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import * as echarts from 'echarts';
  export default {
    name: 'Seo',
    components: {

    },
    data() {
      return {
        type:0
      }
    },
    computed: {

    },
    mounted(){
      this.initEchart('chart1','Traffic Acquistion')
      this.initEchart('chart2','Device Category')
      this.initEchart('chart3','Source Channels')
      this.initEchart2('chart4','Landing Page')
      this.initEchart3('chart5','Landing Page')
      this.initEchart2('chart6','Landing Page')
    },
    created(){
    },
    methods:{
      initEchart(id,title){
        var chartDom = document.getElementById(id);
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#8886FF',
            '#68D391',
            '#F687B3',
            '#76E4F7'
          ],
          tooltip: {
            trigger: 'item'
          },
          title: {
            text: title,
            textStyle:{
              color:'#2D3648',
              fontSize:20
            },
            left: 'center'
          },
          legend: {
            bottom:'2%'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: [
                { value: 1048, name: 'Search Engine' },
                { value: 735, name: 'Direct' }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        myChart.setOption(option);
      },
      initEchart3(id,title){
        var chartDom = document.getElementById(id);
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#8886FF',
            '#68D391',
            '#F687B3',
            '#76E4F7'
          ],
          tooltip: {
            trigger: 'item'
          },
          title: {
            text: title,
            textStyle:{
              color:'#2D3648',
              fontSize:20
            },
            left: 'center'
          },
          legend: {
            bottom:'2%'
          },
          series: [
            {
              type: 'pie',
              radius: ['40%', '70%'],
              data: [
                { value: 1048, name: 'Search Engine' },
                { value: 735, name: 'Direct' }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        myChart.setOption(option);
      },
      initEchart2(id,title){
        var chartDom = document.getElementById(id);
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#5856D6',
            '#F56565'
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          title: {
            text: title,
            textStyle:{
              color:'#2D3648',
              fontSize:20
            },
            left: 'center'
          },
          legend: {
            data: ['Total', 'Targeted'],
            bottom:'2%'
          },
          grid: {
            left: '4%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Total',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(88, 86, 214, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(88, 86, 214, 0)'
                  }
                ])
              },
              data: [120, 132, 101, 134, 90, 230, 210]
            },
            {
              name: 'Targeted',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(245, 101, 101, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(245, 101, 101, 0)'
                  }
                ])
              },
              data: [20, 20, 20, 20, 20, 20, 20]
            }
          ]
        };
        myChart.setOption(option);
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .type{display: flex;justify-content: center;margin-top:20px;}
  .type div{display: flex;align-items: center;justify-content: center;background-color: rgba(0, 0, 0, 0.08);border-radius: 8px;}
  .type div span{line-height: 50px;width: 160px;text-align: center;font-size:20px;color:#21272A;cursor: pointer;}
  .type div .act{color:#5856D6;background-color: #ffffff;border-radius: 8px;box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);}
  .seo{display: flex;flex-direction: column;}
  .seo .img{background-color: #ffffff;margin-bottom:14px;padding:20px;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;}
  .seo .img img{max-height:60px;}
  .seo .img div{border-top:1px solid #A0AEC0;margin-top:10px;padding-top:10px;display: flex;flex-direction: column;}
  .seo .img p{font-size: 12px;color:#4A5468;line-height: 2;font-weight: normal;}
  .seo .img h4{font-size: 12px;color:#4A5468;line-height: 2;font-weight: bold;display: flex;align-items: center;}
  .seo .img .map{width: 100%;margin-top:10px;max-height:10000px;max-width: 1000px;}
  .seo .img b{color:#4A5468;font-size:16px;padding:10px 0;}
  .seo .img a{color:#4299E1;font-size:12px;text-decoration: underline;cursor: pointer;}
  .seo .list{display: grid;gap: 14px;grid-template-columns: 1fr 1fr 1fr 1fr;}
  .seo .list .item{height:80px;background-color: #ffffff;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;padding:0 30px;display: flex;align-items: center;}
  .seo .list .item i{color:#5856D6;font-size:30px;margin-right: 20px;}
  .seo .list .item div{display: flex;flex-direction: column;font-size: 12px;color:#A0AEC0;}
  .seo .list .item div b{font-size: 20px;color: #2D3748;}
  .charts{display: grid;gap: 20px;grid-template-columns: 1fr 1fr;}
  .charts .item{background-color: #ffffff;padding:20px;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;}
  h1{font-size: 20px;color: #2D3648;text-align: center;}
  .charts .item div{height:300px;}
  .title{display: flex;justify-content: space-between;align-items: center;height:50px;font-size:20px;color:#2D3648;font-weight: bold;padding:0 10px;}
  .time{display: flex;align-items: center;color:#5856D6;font-size: 14px;font-weight: bold;}
  .time span{border:1px solid #5856D6;height:20px;width:20px;border-radius: 5px;display: flex;justify-content: center;align-items: center;cursor: pointer;margin:0 14px;}
  .time span i{font-size:12px;font-weight: bold;}
  .tables{background-color: #ffffff;margin-bottom:14px;padding:20px;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;display: grid;gap: 20px;grid-template-columns: 1fr 1fr;}
  .tables .item{display: flex;flex-direction: column;}
  .tables .item h1{text-align: left;}
  .tables .item .table {border-top:1px solid #EDF2F7;border-bottom: 1px solid #EDF2F7;margin:20px 0;}
  .tables .item .table .header{background-color: #F8F9FA;height:40px;display: flex;align-items: center;color:#4A5468;font-size:14px;}
  .tables .item .table span{padding:0 20px;display: flex;align-items: center;}
  .tables .item .table span:nth-child(1){width:100%;}
  .tables .item .table span:nth-child(2){width: 120px;flex-shrink: 0;}
  .tables .item .table .row{border-bottom:1px dashed #EDF2F7;height:40px;display: flex;align-items: center;color:#1A202C;font-size:14px;}
  .tables .item .table .row:last-child{border-bottom-width: 0;}
  .tables .item .table .row a{color:#48BB78;font-size:12px;margin-left:10px;}
  .tables .item .table .header span{font-weight: bold;}
  .tables .item p{background-color: #F7F9FC;padding:10px 20px;line-height: 2;font-size: 14px;color:#1A202C;}
  @media (max-width: 768px) {
    .seo .list{gap: 14px;grid-template-columns: 1fr;}
    .tables{grid-template-columns: 1fr;}
    .charts{grid-template-columns: 1fr;}
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .seo .list{height:174px;display: grid;gap: 14px;grid-template-columns: 1fr 1fr;}
  }
</style>
