<template>
  <div class="login">
    <div class="bg"></div>
    <div class="form">
      <img src="../assets/imgs/logo.png"/>
      <h1>Welcome!</h1>
      <span>Enter your email and password to sign in</span>
      <div class="div act">
        <input placeholder="e-mail" />
      </div>
      <div class="div">
        <input type="password" placeholder="Password"/>
        <img class="pass" src="../assets/imgs/visibility.png"/>
      </div>
      <div class="rem">
        <label>
          <input class="input" type="checkbox"/>
          Remember Me
        </label>

        <a>Find e-mail & password</a>
      </div>
      <div class="btn">Login</div>
      <div class="copy">Copyright © 2024  ditans., Ltd. All rights reserved</div>
    </div>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import Vue from 'vue'


  export default {
    name: 'Login',
    components: {
    },
    data() {
      return {

      }
    },
    computed: {

    },
    created() {

    },
    methods: {

    }
  }
</script>

<style scoped>
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .login{display: flex;justify-content: center;align-items: center;height:100vh;}
  .bg{position: fixed;top:20px;left:20px;z-index: -1;width:calc(100vw - 40px);border-radius: 10px;height:40vh;display: flex;background-image: url(../assets/imgs/login_bg.jpg);background-size: 100% 100%;}
  .form{background-color: #ffffff;width:404px;box-shadow: 0px 6px 6px -1px rgba(0, 0, 0, 0.1);border-radius: 16px;padding:32px 42px;display: flex;flex-direction: column;}
  .form img{height:26px;width:106px;}
  .form h1{color:#5856D6;font-size:28px;margin-top:32px;line-height: 1;}
  .form span{color:#A0AEC0;font-size:14px;margin-top:10px;margin-bottom:32px;}
  .form .div{border: 1px solid #CBD5E0;border-radius: 6px;overflow: hidden;margin-bottom:14px;padding:10px 14px;display: flex;align-items: center;}
  .form .act{border:1px solid #5856D6;}
  .form input{border:none;height:20px;font-size:14px;width: 100%;}
  .form .rem{display: flex;justify-content: space-between;align-items: center;font-size:12px;}
  .form .btn{height:52px;width:100%;background-color: #5856D6;cursor: pointer;color:#ffffff;font-size:17px;display: flex;align-items: center;justify-content: center;border-radius: 8px;margin:32px 0;}
  .form .copy{color:#A0AEC0;font-size:12px;align-self: center;}
  .form a{color:#A0AEC0;cursor: pointer;}
  .form label{display:flex;align-items: center;color:#718096;}
  .form label .input{width:20px;height:20px;border-radius: 4px;margin-right:10px;}
  .form .pass{width:16px;height:16px;cursor: pointer;}
  @media (max-width: 768px) {
    .form{width:80vw;padding:20px 20px 40px 20px;border-radius: 10px;}
    .form h1{font-size:26px;}
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
  }
</style>
