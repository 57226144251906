<template>
  <div id="home">

    <div class="div">
      <div class="title">
        Company Management
        <div>
          <el-button icon="el-icon-unlock" type="primary" plain>Access</el-button>
          <el-button icon="el-icon-delete" type="info" plain>Delete</el-button>
          <el-button icon="el-icon-plus" type="primary" @click="addVisible = true">Add</el-button>
        </div>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{ fontSize: '14px', backgroundColor: '#F8F9FA',color:'#4A5468',fontWeight:'normal'}"
        :cell-style="{ fontSize: '14px'}"
        style="width: 100%;">
        <el-table-column
          sortable
          min-width="300"
          label="Profile">
          <template slot-scope="scope">
            <div class="profile">
              <input type="checkbox"/>
              <img src="../assets/imgs/logo_top_m.png"/>
              <p>
                <b>Pagac Group</b>
                Michelle Thiel PhD
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="busimessLocation"
          min-width="200"
          sortable
          label="Busimess Location">
        </el-table-column>
        <el-table-column
          prop="address"
          min-width="200"
          sortable
          label="address">
        </el-table-column>
        <el-table-column
          prop="email"
          min-width="200"
          sortable
          label="Email">
        </el-table-column>
        <el-table-column
          prop="contact"
          min-width="200"
          sortable
          label="Contact">
        </el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination
          layout="prev, pager, next"
          :total="1000">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="Edit User Information" :width="dialogWidth" :visible.sync="addVisible">
      <div class="form">
        <div>
          Corp. Name
          <input placeholder="Corp. Name"/>
        </div>
        <div>
          E-Mail
          <input placeholder="E-Mail"/>
        </div>
        <div>
          Busimess Location
          <input placeholder="Busimess Location"/>
        </div>
        <div>
          Address 1
          <input placeholder="Street address"/>
        </div>
        <div>
          Address 2
          <input placeholder="Apt, Suite, Building (optional)"/>
        </div>
        <div class="flex">
          <div>
            Zip code
            <input placeholder="Zip code"/>
          </div>
          <div>
            State
            <select>
              <option>Selected State</option>
            </select>
          </div>
        </div>
        <div class="flex">
          <div>
            City
            <input placeholder="City"/>
          </div>
          <div>
            Country
            <select>
              <option>Selected Country</option>
            </select>
          </div>
        </div>
        <div>
          Contact
          <input placeholder="Contact"/>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addVisible = false">Confirm</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { Table, TableColumn, Pagination, Avatar, Button,Dialog } from 'element-ui'
  export default {
    name: 'Admin',
    components: {
      'el-table': Table,
      'el-table-column': TableColumn,
      'el-pagination':Pagination,
      'el-avatar':Avatar,
      'el-button':Button,
      'el-dialog':Dialog
    },
    data() {
      return {
        dialogWidth:'800px',
        addVisible:false,
        tableData: [{
          busimessLocation: 'Alexander Marquez',
          address: 'Romania Berkshire Kohlerton',
          email:'hyunwooeyi@gmail.com',
          contact:'(626) 665-1308'
        }, {
          busimessLocation: 'Alexander Marquez',
          address: 'Romania Berkshire Kohlerton',
          email:'hyunwooeyi@gmail.com',
          contact:'(626) 665-1308'
        }, {
          busimessLocation: 'Alexander Marquez',
          address: 'Romania Berkshire Kohlerton',
          email:'hyunwooeyi@gmail.com',
          contact:'(626) 665-1308'
        }, {
          busimessLocation: 'Alexander Marquez',
          address: 'Romania Berkshire Kohlerton',
          email:'hyunwooeyi@gmail.com',
          contact:'(626) 665-1308'
        }]
      }
    },
    computed: {

    },
    mounted() {
      window.onresize = () => {
        return (() => {
          this.setDialogWidth()
        })()
      }
    },
    created(){

    },
    methods:{
      setDialogWidth() {
        var val = document.body.clientWidth
        const def = 800 // 默认宽度
        if (val < def) {
          this.dialogWidth = '100%'
        } else {
          this.dialogWidth = def + 'px'
        }
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .div{background-color: #ffffff;padding: 30px;display: grid;gap: 20px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .title div{display: flex;align-items: center;}
  .title div *{padding:10px 16px;font-size: 14px;;}
  .profile{display: flex;align-items: center;}
  .profile input{width: 18px;height:18px;flex-shrink: 0;}
  .profile img{width: 36px;height:36px;flex-shrink: 0;margin:0 10px;border-radius: 4px;}
  .profile p{display: flex;flex-direction: column;color:#7F7F7F;}
  .profile p b{color:#000000;}
  .pager{display: flex;justify-content: center;}
  .form{display: flex;flex-direction: column;}
  .form .flex{display: grid;grid-template-columns:1fr 1fr;gap: 20px;padding-bottom: 0;}
  .form div{display: flex;flex-direction: column;color:#A0ABC0;font-weight: bold;padding-bottom:20px;}
  .form div input{border-radius: 6px;border:1px solid #E2E8F0;height:40px;padding:0 20px;margin-top:10px;}
  .form div select{border-radius: 6px;border:1px solid #E2E8F0;height:40px;padding:0 20px;margin-top:10px;box-sizing: border-box;}
  @media (max-width: 768px) {
    .title{flex-direction: column;}
    .title div{margin-top:20px;}
    .div{padding:20px 5px;}
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {

  }
</style>
