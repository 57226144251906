<template>
  <div id="home">
    <div class="filter">
      <h3>Filter</h3>
      <div class="item">
        <div>
          <p>
            Quick
            <select>
              <option>Last 30 Days</option>
            </select>
          </p>
        </div>
      </div>
      <div class="item">
        <div>
          <p>
            Start
            <input value="8/14/2023"/>
          </p>
          <p>
            End
            <input value="8/14/2023"/>
          </p>
        </div>
        <h2>Apply</h2>
      </div>
    </div>
    <div class="social">
      <div class="title">
        Social
        <span>
          <i class="el-icon-folder-checked"></i>
          Mar 16 - Apr 14
        </span>
      </div>
      <div class="bars">
        <div @click="barIndex = 0" :class="barIndex == 0 ? 'act' : ''">
          <img src="../assets/imgs/icons/icon_pro1.png"/>
          Instagram
        </div>
        <div @click="barIndex = 1" :class="barIndex == 1 ? 'act' : ''">
          <img src="../assets/imgs/icons/icon_pro2.png"/>
          Facebook
        </div>
        <div @click="barIndex = 2" :class="barIndex == 2 ? 'act' : ''">
          <img src="../assets/imgs/icons/icon_pro3.png"/>
          TikTok
        </div>
        <div @click="barIndex = 3" :class="barIndex == 3 ? 'act' : ''">
          <img src="../assets/imgs/icons/icon_pro4.png"/>
          LinkedIn
        </div>
      </div>
      <div class="instagram" v-show="barIndex == 0">
        <div class="types">
          <div>
            <span :class="typeIndex == 0 ? 'act' : ''" @click="typeIndex = 0">Overview</span>
            <span :class="typeIndex == 1 ? 'act' : ''" @click="typeIndex = 1">Follows</span>
            <span :class="typeIndex == 2 ? 'act' : ''" @click="typeIndex = 2">Content</span>
          </div>
          <a>
            <i class="el-icon-top-right"></i>
            Go to Platform
          </a>
        </div>
        <div class="main" v-show="typeIndex == 0">
          <div class="left">
            <div class="div">
              <h2>Follower Breakdown</h2>
              <h3><span>1,777</span>followers</h3>
              <p><span class="green">+2.4%</span>Dec 30 - Jan 5</p>
            </div>
            <div class="div">
              <h2>Insights Overview</h2>
              <p>You reached<span class="green">+1,32%</span>more accounts</p>
              <p>compared to May 29 - Jun 27</p>
            </div>
            <div class="list">
              <div class="item">
                Accounts reached
                <a>
                  1,920
                  <b class="green">+210%</b>
                </a>
              </div>
              <div class="item">
                Accounts reached
                <a>
                  64
                  <b class="red">-32%</b>
                </a>
              </div>
              <div class="item">
                Total followers
                <a>
                  2
                  <b>0%</b>
                </a>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="div">
              <h2>By Content Interactions</h2>
              <div class="line">
                <div class="item">
                  <span>Posts</span>
                  <span class="a">
                    <a style="width: 100%;"></a>
                  </span>
                  <span>952</span>
                </div>
                <div class="item">
                  <span>Reels</span>
                  <span class="a">
                    <a style="width: 40%;"></a>
                  </span>
                  <span>102</span>
                </div>
                <div class="summary">
                  <b>-</b>Followers and non-followers
                </div>
              </div>
            </div>
            <h2>Top Post</h2>
            <div class="based">
              Based on likes
              <a>See all</a>
            </div>
            <swiper class="imgs" :options="swiperOptions" ref="mySwiper">
              <swiper-slide class="item" v-for="item,index in 6" :key="index">
                <img src="https://img0.baidu.com/it/u=3452548144,1566320356&fm=253&fmt=auto&app=138&f=JPEG?w=714&h=500"/>
                #1
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="main followsMain" v-show="typeIndex == 1">
          <div class="div">
            <h2>Follower Breakdown</h2>
            <h3><span>1,777</span>followers</h3>
            <p><span class="green">+2.4%</span>Dec 30 - Jan 5</p>
          </div>
          <div class="follows">
            <div class="tuli">
              <a>Growth</a>
              <div class="growth">
                <div class="item act" @click="initEchart">
                  <b style="background-color: #5856D6;"></b>
                  <p>Overall</p>
                  <span>53</span>
                </div>
                <div class="item" @click="initEchart">
                  <b style="background-color: #48BB78;"></b>
                  <p>Follows</p>
                  <span>61</span>
                </div>
                <div class="item" @click="initEchart">
                  <b style="background-color: #F56565;"></b>
                  <p>Unfollows</p>
                  <span>8</span>
                </div>
              </div>
            </div>
            <div class="charts" id="basic_dashboard"></div>
          </div>
        </div>
        <div class="main" v-show="typeIndex == 2">
          <div class="contentList">
            <div class="listHeader">
              <i class="el-icon-menu"></i>
              Post
            </div>
            <div class="item" v-for="item,index in 5" :key="index">
              <img src="https://img0.baidu.com/it/u=3452548144,1566320356&fm=253&fmt=auto&app=138&f=JPEG?w=714&h=500"/>
              <div class="info">
                <span>may 6, 2024</span>
                <span>10:43 AM</span>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority sheets containing There are many variations of passages of Lorem Ipsum available, but the majority sheets containing There are many varia...</p>
              </div>
              <div class="right">
                <span>Likes</span>
                <p>
                  <i style="color:#F56565;" class="el-icon-star-on"></i>6,404
                </p>
              </div>
              <div class="right">
                <span>Comments</span>
                <p>
                  <i class="el-icon-chat-dot-square"></i><a>View</a>
                </p>
              </div>
              <div class="right">
                <span>Comment count</span>
                <p>
                  49
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="instagram" v-show="barIndex == 1">
        <div class="types">
          <div>
            <span :class="typeIndex == 0 ? 'act' : ''" @click="typeIndex = 0">Overview</span>
            <span :class="typeIndex == 1 ? 'act' : ''" @click="typeIndex = 1">Follows</span>
            <span :class="typeIndex == 2 ? 'act' : ''" @click="typeIndex = 2">Content</span>
          </div>
          <a>
            <i class="el-icon-top-right"></i>
            Go to Platform
          </a>
        </div>
        <div class="main" v-show="typeIndex == 0">
          <div class="left">
            <div class="div">
              <h2>Page Overview</h2>
              <h3><span>1,777</span>followers</h3>
              <p><span class="green">+2.4%</span>Last 28 days</p>
            </div>
            <div class="list">
              <div class="item">
                Post reach
                <a>
                  1,920
                </a>
              </div>
              <div class="item">
                Post engagement
                <a>
                  64
                </a>
              </div>
              <div class="item">
                New Page likes
                <a>
                  2
                </a>
              </div>
              <div class="item">
                New followers
                <a>
                  2
                </a>
              </div>
              <div class="detailBtn">See Details</div>
            </div>
          </div>
          <div class="right">
            <div class="div">
              <h2>Audicence</h2>
              <p class="title2">These values are based on total followers of your Page or Profile.</p>
            </div>
            <hr/>
            <div class="div">
              <h2>Age and Gender</h2>
              <div class="genderTuli">
                <div class="item">
                  <b style="background-color: #5856D6;"></b>
                  <a>Men</a>
                  59%
                </div>
                <div class="item">
                  <b style="background-color: #0BC5EA;"></b>
                  <a>Women</a>
                  41%
                </div>
              </div>
              <div class="charts" id="basic_dashboard2"></div>
            </div>
            <hr/>
            <div class="div">
              <h2 class="btnFlex">
                Top Locations
                <div class="btnType">
                  <span class="act">Cities</span>
                  <span>Countries</span>
                </div>
              </h2>
              <div class="locations">
                <div class="item">
                  <span>Wardside</span>
                  <span>
                    <a style="width: 40%;"></a>
                  </span>
                  <span>184</span>
                </div>
                <div class="item">
                  <span>East Juliehaven</span>
                  <span>
                    <a style="width: 36%;"></a>
                  </span>
                  <span>165</span>
                </div>
                <div class="item">
                  <span>Dickinsonville</span>
                  <span>
                    <a style="width: 30%;"></a>
                  </span>
                  <span>89</span>
                </div>
                <div class="item">
                  <span>Maymieview</span>
                  <span>
                    <a style="width: 26%;"></a>
                  </span>
                  <span>45</span>
                </div>
                <div class="item">
                  <span>East Alessiaborough</span>
                  <span>
                    <a style="width: 10%;"></a>
                  </span>
                  <span>24</span>
                </div>
              </div>
            </div>

            <div class="detailBtn">See More</div>
          </div>
        </div>
        <div class="main followsMain" v-show="typeIndex == 1">
          <div class="div">
            <h2>Follower Breakdown</h2>
            <h3><span>1,777</span>followers</h3>
            <p><span class="green">+2.4%</span>Dec 30 - Jan 5</p>
          </div>
          <div class="follows">
            <div class="tuli">
              <a>Growth</a>
              <div class="growth">
                <div class="item act" @click="initEchart">
                  <b style="background-color: #5856D6;"></b>
                  <p>Overall</p>
                  <span>53</span>
                </div>
                <div class="item" @click="initEchart">
                  <b style="background-color: #48BB78;"></b>
                  <p>Follows</p>
                  <span>61</span>
                </div>
                <div class="item" @click="initEchart">
                  <b style="background-color: #F56565;"></b>
                  <p>Unfollows</p>
                  <span>8</span>
                </div>
              </div>
            </div>
            <div class="charts" id="basic_dashboard3"></div>
          </div>
        </div>
        <div class="main" v-show="typeIndex == 2">
          <div class="contentList">
            <div class="listHeader">
              <i class="el-icon-menu"></i>
              Post
            </div>
            <div class="item" v-for="item,index in 5" :key="index">
              <img src="https://img0.baidu.com/it/u=3452548144,1566320356&fm=253&fmt=auto&app=138&f=JPEG?w=714&h=500"/>
              <div class="info">
                <span>may 6, 2024</span>
                <span>10:43 AM</span>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority sheets containing There are many variations of passages of Lorem Ipsum available, but the majority sheets containing There are many varia...</p>
              </div>
              <div class="right">
                <span>Likes</span>
                <p>
                  <i style="color:#F56565;" class="el-icon-star-on"></i>6,404
                </p>
              </div>
              <div class="right">
                <span>Comments</span>
                <p>
                  <i class="el-icon-chat-dot-square"></i><a>View</a>
                </p>
              </div>
              <div class="right">
                <span>Comment count</span>
                <p>
                  49
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="instagram" v-show="barIndex == 2 || barIndex == 3">
        <div class="types">
          <div>
            <span :class="typeIndex == 0 ? 'act' : ''" @click="typeIndex = 0">Overview</span>
            <span :class="typeIndex == 1 ? 'act' : ''" @click="typeIndex = 1">Follows</span>
            <span :class="typeIndex == 2 ? 'act' : ''" @click="typeIndex = 2">Content</span>
          </div>
          <a>
            <i class="el-icon-top-right"></i>
            Go to Platform
          </a>
        </div>
        <div class="main tiktopMain" v-show="typeIndex == 0">
          <div class="tiktopInfo">
            <h2>Analytcs<a>More info<i class="el-icon-more"></i></a></h2>
            <div class="list">
              <div class="item act">
                New followers
                <p>
                  2
                  <b class="green">1 (-100.00)</b>
                  <i class="el-icon-top green"></i>
                </p>
              </div>
              <div class="item">
                New likes
                <p>
                  0
                  <b>0 (0.00%)</b>
                </p>
              </div>
              <div class="item">
                New profile visits
                <p>
                  2
                  <b class="green">1 (-100.00)</b>
                  <i class="el-icon-top green"></i>
                </p>
              </div>
              <div class="item">
                New vedio views
                <p>
                  2
                  <b class="green">1 (-100.00)</b>
                  <i class="el-icon-top green"></i>
                </p>
              </div>
            </div>
          </div>
          <div class="tiktopInfo">
            <h2>Engagement<a>More info<i class="el-icon-more"></i></a></h2>
            <div class="list list3">
              <div class="item">
                Followers
                <p>
                  2
                  <b class="green">1 (-100.00)</b>
                  <i class="el-icon-top green"></i>
                </p>
              </div>
              <div class="item">
                New likes
                <p>
                  0
                  <b>0 (0.00%)</b>
                </p>
              </div>
              <div class="item">
                New profile visits
                <p>
                  2
                  <b class="green">1 (-100.00)</b>
                  <i class="el-icon-top green"></i>
                </p>
              </div>
            </div>
          </div>
          <div class="tiktopInfo">
            <h2>Profile views</h2>
            <div id="profileViewsCharts"></div>
          </div>
          <div class="tiktopInfo">
            <h2>Video views</h2>
            <div class="videoTitle">
              <h4>4,453,225<span>views</span></h4>
              <h5><span class="green">+2.4%</span>Last 7 days</h5>
            </div>
            <div id="videoViewsCharts"></div>
          </div>
        </div>
        <div class="main followsMain" v-show="typeIndex == 1">
          <div class="div">
            <h2>Follower Breakdown</h2>
            <h3><span>1,777</span>followers</h3>
            <p><span class="green">+2.4%</span>Dec 30 - Jan 5</p>
          </div>
          <div class="follows">
            <div class="tuli">
              <a>Growth</a>
              <div class="growth">
                <div class="item act" @click="initEchart">
                  <b style="background-color: #5856D6;"></b>
                  <p>Overall</p>
                  <span>53</span>
                </div>
                <div class="item" @click="initEchart">
                  <b style="background-color: #48BB78;"></b>
                  <p>Follows</p>
                  <span>61</span>
                </div>
                <div class="item" @click="initEchart">
                  <b style="background-color: #F56565;"></b>
                  <p>Unfollows</p>
                  <span>8</span>
                </div>
              </div>
            </div>
            <div class="charts" id="basic_dashboard3"></div>
          </div>
        </div>
        <div class="main" v-show="typeIndex == 2">
          <div class="contentList">
            <div class="listHeader">
              <i class="el-icon-menu"></i>
              Post
            </div>
            <div class="item" v-for="item,index in 5" :key="index">
              <img src="https://img0.baidu.com/it/u=3452548144,1566320356&fm=253&fmt=auto&app=138&f=JPEG?w=714&h=500"/>
              <div class="info">
                <span>may 6, 2024</span>
                <span>10:43 AM</span>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority sheets containing There are many variations of passages of Lorem Ipsum available, but the majority sheets containing There are many varia...</p>
              </div>
              <div class="right">
                <span>Likes</span>
                <p>
                  <i style="color:#F56565;" class="el-icon-star-on"></i>6,404
                </p>
              </div>
              <div class="right">
                <span>Comments</span>
                <p>
                  <i class="el-icon-chat-dot-square"></i><a>View</a>
                </p>
              </div>
              <div class="right">
                <span>Comment count</span>
                <p>
                  49
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { swiper, swiperSlide } from "vue-awesome-swiper";
  import * as echarts from 'echarts';
  import 'swiper/css/swiper.css'
  export default {
    name: 'SocialMedia',
    components: {
      swiper, swiperSlide
    },
    data() {
      return {
        barIndex:0,
        typeIndex:0,
        swiperOptions:{
          slidesPerView:4.5,
          spaceBetween:20
        }
      }
    },
    computed: {

    },
    mounted(){
      this.initEchart()
      this.initEchart2()
      this.initEchart3()
      this.initEchart4()
    },
    created(){

    },
    methods:{
      initEchart(){
        var chartDom = document.getElementById('basic_dashboard');
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#5856D6',
            '#48BB78',
            '#F56565'
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '4%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Total',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(88, 86, 214, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(88, 86, 214, 0)'
                  }
                ])
              },
              data: [120, 132, 101, -100, 90, 50, -60]
            }
          ]
        };
        myChart.setOption(option);
        myChart.resize()
      },
      initEchart2(){
        var chartDom = document.getElementById('basic_dashboard2');
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#5856D6',
            '#76E4F7'
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '6%',
            right: '2%',
            bottom: '8%'
          },
          xAxis: [
            {
              type: 'category',
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Men',
              type: 'bar',
              barWidth:'20px',
              data: [60, 132, 101, 100, 90]
            },
            {
              name: 'Women',
              type: 'bar',
              barWidth:'20px',
              data: [120, 120, 80, 50, 60]
            }
          ]
        };
        myChart.setOption(option);
        myChart.resize()
      },
      initEchart3(){
        var chartDom = document.getElementById('profileViewsCharts');
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#5856D6',
            '#F56565'
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            data: ['Total', 'Targeted'],
            bottom:'2%'
          },
          grid: {
            left: '0%',
            right: '0%',
            bottom: '12%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Total',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(88, 86, 214, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(88, 86, 214, 0)'
                  }
                ])
              },
              data: [120, 132, 101, 134, 90, 230, 210]
            },
            {
              name: 'Targeted',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(245, 101, 101, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(245, 101, 101, 0)'
                  }
                ])
              },
              data: [20, 20, 20, 20, 20, 20, 20]
            }
          ]
        };
        myChart.setOption(option);
        myChart.resize()
      },
      initEchart4(){
        var chartDom = document.getElementById('videoViewsCharts');
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#5856D6'
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '0%',
            right: '0%',
            bottom: '2%',
            containLabel: true
          },
          xAxis: {
              type: 'category',
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed']
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: [120, 200, 150, 80, 70, 110, 130, 260, 60, 160],
                type: 'bar',
                barWidth:'40px'
              }
            ]
        };
        myChart.setOption(option);
        myChart.resize()
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}

  .filter{background-color: #ffffff;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;padding: 20px 30px;}
  .filter h3{color:#2D3648;font-size: 20px;margin-bottom:30px;}
  .filter .item{display: flex;justify-content: space-between;align-items: flex-end;margin-bottom:10px;}
  .filter .item div{display: flex;}
  .filter .item p{display: flex;flex-direction: column;color: #A0ABC0;margin-right: 20px;}
  .filter .item input{width: 260px;height:36px;border-radius: 5px;border: 1px solid #EDF0F7;margin-top:10px;font-size: 14px;padding:0 20px;color:#717D96;}
  .filter .item select{width: 260px;height:36px;border-radius: 5px;border: 1px solid #EDF0F7;margin-top:10px;font-size: 14px;padding:0 20px;color:#717D96;}
  .filter .item h2{width: 80px;height: 40px;display: flex;justify-content: center;align-items: center;cursor: pointer;background-color: #5856D6;border-radius: 6px;color:#ffffff;font-size: 14px;font-weight: normal;}
  .social{display: flex;flex-direction: column;background-color: #ffffff;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;padding:20px;}
  .social .title{font-size: 20px;color: #2D3648;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .social .title span{color:#5856D6;font-weight:normal;font-size: 18px;}
  .social .title span i{margin-right: 6px;}
  .social .bars{display: flex;margin:20px 0;}
  .social .bars div{border-top:1px solid #ffffff;border-bottom:1px solid #CBD5E0;cursor: pointer;width: 160px;height:50px;display: flex;justify-content: center;align-items: center;font-size: 18px;color: #2D3748;border-radius: 6px 6px 0 0;}
  .social .bars .act{border:1px solid #CBD5E0;border-bottom-color: #ffffff;color: #5856D6;}
  .social .bars div img{width: 16px;height:16px;margin-right: 10px;}
  .instagram .types{display: flex;align-items: center;justify-content: space-between;margin:10px 0;}
  .instagram .types div{display: flex;}
  .instagram .types span{line-height: 40px;padding:0 20px;border-radius: 20px;border:1px solid #CBD5E0;margin-right: 10px;cursor: pointer;}
  .instagram .types .act{background-color: #ECECFF;border-color: #ECECFF;color: #5856D6;}
  .instagram .types a{width: 170px;height: 40px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;display: flex;justify-content: center;align-items: center;}
  .instagram .types a i{margin-right: 10px;}
  .main{display: flex;justify-content: center;}
  .main .left{width: 50%;border-right: 1px solid #CBD5E0;padding:30px;display: flex;flex-direction: column;align-items: center;}
  .main h3{display: flex;align-items: center;color: #717D96;font-size: 18px;}
  .main h3 span{font-size: 26px;color: #5856D6;margin-right: 6px;}
  .main p{font-size: 14px;color: #4A5468;line-height: 1.6;}
  .main p span{margin:0 10px;}
  .main .left .list{width: 100%;}
  .title2{width: 100%;color: #717D96;font-size: 18px;}
  .genderTuli{align-self: flex-start;padding-left:5px;}
  .genderTuli .item{display: flex;align-items: center;height: 40px;font-size: 14px;color: #4A5468;font-weight: bold;}
  .genderTuli .item b{width: 12px;height: 12px;margin-right: 10px;}
  .genderTuli .item a{width: 80px;color: #718096;font-weight: normal;}
  .btnFlex{display: flex;justify-content: space-between;width: 100%;align-items: center;}
  .btnType{display: flex;background-color: #E5E5EA;border-radius: 10px;padding: 3px;}
  .btnType span{width: 120px;height:30px;display: flex;font-weight: normal;justify-content: center;align-items: center;font-size: 14px;color: #21272A;border-radius: 7px;cursor: pointer;}
  .btnType .act{color: #5856D6;font-weight: bold;background-color: #ffffff;}
  .locations{width: 100%;margin-top:20px;}
  .locations .item{display: flex;align-items: center;height:40px;color: #4A5468;font-size: 14px;}
  .locations .item span:nth-child(1){width: 180px;flex-shrink: 0;}
  .locations .item span:nth-child(2){width: 100%;background-color: #EDF0F7;height: 20px;border-radius: 10px;display: flex;}
  .locations .item span:nth-child(3){width: 50px;flex-shrink: 0;margin-left:20px;text-align: right;}
  .locations .item span a{background-color: #5856D6;border-radius: 10px 0 0 10px;height: 20px;}
  hr{background-color: #CBD5E0;border: none;height: 1px;margin:10px 0;}
  .main .left .list .item{height:80px;display: flex;padding:0 20px;justify-content: space-between;color: #2D3648;align-items: center;font-size: 14px;border-bottom:1px solid #EDF2F7;}
  .main .left .list .item a{display: flex;flex-direction: column;font-size: 20px;color: #2D3748;align-items: flex-end;line-height: 1;}
  .main .left .list .item a b{font-size: 14px;margin-top:10px;color: #A0ABC0;}
  .green{color:#48BB78 !important;}
  .red{color:#F56565 !important;}
  .main .div{display: flex;flex-direction: column;align-items: center;justify-content: center;padding:30px 0;}
  .main h2{color:#2D3748;font-size: 26px;line-height: 1;margin-bottom:20px;}
  .main .right{width: 50%;padding:30px;}
  .main .right h2{align-self: flex-start;}
  .main .right .line{display: flex;width: 100%;flex-direction: column;align-items: center;}
  .main .right .line .item{display: flex;width: 100%;align-items: center;height: 40px;}
  .main .right .line .item span{color:#4A5468;font-size: 14px;flex-shrink: 0;}
  .main .right .line .item .a{width: 100%;flex-shrink: 1;margin:0 30px;background-color: #EDF0F7;height: 3px;display: flex;align-items: center;border-radius: 3px;}
  .main .right .line .item .a a{background-color: #5856D6;height:3px;border-radius: 3px;}
  .main .right .line .summary{display: flex;align-items: center;justify-content: center;color: #718096;font-size: 14px;margin:20px 0 50px 0;}
  .main .right .line .summary b{color: #5856D6;margin-right: 10px;font-size: 30px;font-weight: normal;}
  .main .right .based{display: flex;color:#2D3648;align-items: center;justify-content: space-between;font-weight: bold;margin-bottom:20px;}
  .main .right .based a{font-size: 14px;color: #5856D6;font-weight: normal;cursor: pointer;}
  .main .right .imgs{width: 100%;overflow: hidden;}
  .main .right .imgs .item{display: flex;flex-direction: column;font-weight: bold;color: #4A5468;margin-right: 10px;align-items: center;}
  .main .right .imgs .item img{border-radius: 8px;width: 100%;margin-bottom:10px;}
  .followsMain{flex-direction: column;}
  .follows{width: 100%;display: flex;align-items: center;}
  .follows .tuli{width: 260px;flex-shrink: 0;margin-right: 30px;height: 200px;border: 1px solid #CBD5E0;border-radius: 16px;padding:30px;display: flex;flex-direction: column;justify-content: space-between;}
  .follows .tuli a{font-size: 14px;color: #2D3748;}
  .follows .tuli .growth .item{height: 35px;display: flex;align-items: center;font-size: 14px;color: #717D96;cursor: pointer;}
  .follows .tuli .growth .act{color: #000000;}
  .follows .tuli .growth .item b{width: 10px;height: 10px;border-radius: 50%;margin-right: 10px;flex-shrink: 0;}
  .follows .tuli .growth .item p{width: 100%;}
  .follows .tuli .growth .item span{flex-shrink: 0;}
  .tiktopMain{display: grid;gap:30px;grid-template-columns: 1fr;}
  .tiktopMain h2{display: flex;justify-content: space-between;width: 100%;margin-top:20px;align-items: center;}
  .tiktopMain h2 a{font-size:14px;color:#717D96;font-weight: normal;cursor: pointer;}
  .tiktopMain h2 a i{color:#717D96;font-size: 14px;margin-left:10px;}
  .tiktopInfo{width: 100%;}
  .tiktopInfo .list3{grid-template-columns: 1fr 1fr 1fr !important;}
  .tiktopInfo .list2{grid-template-columns: 1fr 1fr !important;}
  .tiktopInfo .list{display: grid;gap:30px;grid-template-columns: 1fr 1fr 1fr 1fr;}
  .tiktopInfo .list .item{color:#A0AEC0;font-size:12px;box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);padding:20px;display: flex;flex-direction: column;border-radius: 16px;cursor: pointer;}
  .tiktopInfo .list .item p{margin-top: 10px;display: flex;align-items: center;font-size: 20px;color: #2D3748;font-weight: bold;}
  .tiktopInfo .list .item p b{font-size: 14px;color: #717D96;margin-left:10px;}
  .tiktopInfo .list .item p i{margin-left:10px;font-size: 20px;font-weight: bold;}
  .tiktopInfo .list .act{background-color: #5856D6;color:#ffffff !important;}
  .tiktopInfo .list .act p{color:#ffffff !important;}
  #profileViewsCharts{height:300px;}
  #videoViewsCharts{height:300px;}
  .videoTitle{display: flex;flex-direction: column;align-items: center;}
  .videoTitle h4{font-size: 26px;color: #5856D6;display: flex;align-items: center;}
  .videoTitle h4 span{font-size: 18px;font-weight: normal;color: #717D96;margin-left:10px;}
  .videoTitle h5{color:#4A5468;font-size: 14px;font-weight: normal;margin-top:10px;}
  .videoTitle h5 span{margin-right: 10px;}
  .charts{width: 100%;height: 300px;}
  .contentList{width: 100%;border-top:1px solid #CBD5E0;border-bottom:1px solid #CBD5E0;margin-top:20px;}
  .contentList .listHeader{background-color: #F2F4F8;padding:0 20px;height: 40px;display: flex;align-items: center;color: #4A5468;font-size: 14px;}
  .contentList .listHeader i{margin-right: 10px;color: #717D96;}
  .contentList .item{display: flex;align-items: center;border-bottom: 1px solid #EDF2F7;}
  .contentList .item:last-child{border-bottom-width: 0;}
  .contentList .item img{width: 120px;height: 120px;flex-shrink: 0;}
  .contentList .item .info{width: 100%;margin:0 10px;display: flex;flex-direction: column;font-size: 14px;line-height: 1.5;}
  .contentList .item .info p{overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;white-space: normal;}
  .contentList .item .right{flex-shrink: 0;width:120px;font-size: 14px;padding:10px 0;display: flex;flex-direction: column;}
  .contentList .item .right span{color: #A0ABC0;margin-bottom:10px;}
  .contentList .item .right p{color: #2D3748;font-size: 16px;font-weight: bold;display: flex;align-items: center;}
  .contentList .item .right i{margin-right: 10px;font-size: 20px;}
  .contentList .item .right a{cursor: pointer;color: #4299E1;font-weight: normal;}
  .detailBtn{border:1px solid #5856D6;border-radius: 6px;display: flex;align-items: center;justify-content: center;cursor: pointer;color:#5856D6;height:40px;width: 100%;margin:20px 0;}
  @media (max-width: 768px) {
    #home{padding:10px;}
    .filter .item div{flex-direction: column;}
    .filter .item select{width: 200px;}
    .filter .item input{width: 200px;}
    .social .bars div{width: 100%;flex-direction: column;font-size: 14px;}
    .tiktopInfo .list3{grid-template-columns: 1fr !important;}
    .tiktopInfo .list2{grid-template-columns: 1fr !important;}
    .tiktopInfo .list{display: grid;gap:30px;grid-template-columns: 1fr;}
    .main{display: grid;grid-template-columns: 1fr;width: 100%;}
    .main .left{border-right-width:0;width: 100%;padding: 10px;}
    .main .right{width:calc(100vw - 60px);padding: 10px;}
    .social .bars div img{margin-right: 0;}
    .follows{flex-direction: column;}
    .contentList .item{flex-wrap: wrap;padding-top:10px;}
    .contentList .item img{width: 100px;height: 100px;}
    .contentList .item .info{width: calc(100vw - 180px);flex-shrink: 0;}
    .contentList .item .right:nth-child(3){margin-right: 50vw;}
    .instagram .types{flex-direction: column;}
    .instagram .types div{margin-bottom:20px;}
    .btnFlex{flex-direction: column;}
    .btnType{margin-top:10px;}
    .locations .item span:nth-child(1){width: 130px;font-size: 12px;}
    .locations .item span:nth-child(3){font-size: 12px;width: 30px;margin-left:10px;}
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .filter .item select{width: 220px;}
    .filter .item input{width: 220px;}
    .tiktopInfo .list3{grid-template-columns: 1fr 1fr !important;}
    .tiktopInfo .list{display: grid;gap:30px;grid-template-columns: 1fr 1fr;}
    .main{display: grid;grid-template-columns: 1fr;width: 100%;}
    .main .left{border-right-width:0;width: 100%;}
    .main .right{width:calc(100vw - 200px);}
  }
</style>
