<template>
  <div id="home">

    <div class="div">
      <div class="form">
        <div class="title">
          Account Information
        </div>
        <div>
          <p>Corp. Name<span>*</span></p>
          <input placeholder="Corp. Name"/>
        </div>
        <div>
          <p>E-Mail<span>*</span></p>
          <input placeholder="E-Mail"/>
        </div>
        <div>
          <p>Busimess Location<span>*</span></p>
          <input placeholder="Busimess Location"/>
        </div>
        <div>
          <p>Address 1<span>*</span></p>
          <input placeholder="Street address"/>
        </div>
        <div>
          Address 2
          <input placeholder="Apt, Suite, Building (optional)"/>
        </div>
        <div class="flex">
          <div>
            <p>Zip code<span>*</span></p>
            <input placeholder="Zip code"/>
          </div>
          <div>
            <p>State<span>*</span></p>
            <select>
              <option>Selected State</option>
            </select>
          </div>
        </div>
        <div class="flex">
          <div>
            <p>City<span>*</span></p>
            <input placeholder="City"/>
          </div>
          <div>
            <p>Country<span>*</span></p>
            <select>
              <option>Selected Country</option>
            </select>
          </div>
        </div>
        <div>
          Contact
          <input placeholder="Contact"/>
        </div>

        <div class="title">
          Account Information
        </div>
        <div>
          <p>Corp. Name<span>*</span></p>
          <input placeholder="Corp. Name"/>
        </div>
        <div>
          <p>E-Mail<span>*</span></p>
          <input placeholder="E-Mail"/>
        </div>
        <div>
          Busimess Location
          <input placeholder="Busimess Location"/>
        </div>
      </div>
      <div class="card">
        <div class="title">
          Social Link
        </div>
        <div class="cardList">
          <div class="item">
            <img src="../assets/imgs/icons/icon_pro2.png"/>
            <span>Facebook</span>
            <div class="userInfo">
              <a>
                <i class="el-icon-user-solid"></i>
              </a>
              ieowi@google.com
            </div>
            <div class="btn">
              <img src="../assets/imgs/icons/icon7.png"/>
              Link with Facebook
            </div>
          </div>
          <div class="item">
            <img src="../assets/imgs/icons/icon_pro6.png"/>
            <span>Google</span>
            <div class="userInfo">
              <a>
                <i class="el-icon-user-solid"></i>
              </a>
              ieowi@google.com
            </div>
            <div class="btn">
              <img src="../assets/imgs/icons/icon_pro6.png"/>
              Link with Google
            </div>
          </div>
          <div class="item gray">
            <img src="../assets/imgs/icons/icon_pro1.png"/>
            <span>Instagram</span>
            <div class="userInfo">
              <p>No account</p>
            </div>
            <div class="btn">
              <img src="../assets/imgs/icons/icon_pro1.png"/>
              Link with Instagram
            </div>
          </div>
          <div class="item gray">
            <img src="../assets/imgs/icons/icon_pro5.png"/>
            <span>Yelp</span>
            <div class="userInfo">
              <p>No account</p>
            </div>
            <div class="btn">
              <img src="../assets/imgs/icons/icon_pro5.png"/>
              Link with Yelp
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import * as echarts from 'echarts';
  export default {
    name: 'AccountSetting',
    components: {

    },
    data() {
      return {

      }
    },
    computed: {

    },
    created(){

    },
    methods:{

    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .div{display: flex;}
  .title{color:#2D3648 !important;font-size: 20px;font-weight: bold;}
  .btn{height:40px;width:86px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;font-size: 14px;display: flex;align-items: center;justify-content: center;}
  .form{display: flex;width:100%;flex-direction: column;background-color: #ffffff;padding: 30px;flex-direction: column;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .form .flex{display: grid;grid-template-columns:1fr 1fr;gap: 20px;padding-bottom: 0;}
  .form div{display: flex;flex-direction: column;color:#A0ABC0;font-weight: bold;padding-bottom:20px;}
  .form div span{color:#E53E3E;font-size: 14px;margin-left:5px;}
  .form div input{border-radius: 6px;border:1px solid #E2E8F0;height:40px;padding:0 20px;margin-top:10px;}
  .form div select{border-radius: 6px;border:1px solid #E2E8F0;height:40px;padding:0 20px;margin-top:10px;box-sizing: border-box;}
  .card{width: 450px;margin-left:30px;flex-shrink: 0;padding-top:30px;}
  .cardList{border:1px solid #CBD5E0;border-radius: 16px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);overflow: hidden;margin-top:20px;}
  .cardList .item{border-bottom: 1px solid rgba(226, 232, 240, 1);padding:20px;background-color: #ffffff;display: flex;flex-direction: column;align-items: center;}
  .cardList .item img{width: 30px;height: 30px;}
  .cardList .item span{font-size: 12px;color:#717D96;margin:3px;}
  .cardList .item .userInfo{display: flex;align-items: center;height:32px;color:#4A5468;font-size: 14px;font-weight: bold;}
  .cardList .item .userInfo a{width: 32px;height: 32px;background-color: #A0AEC0;display: flex;align-items: center;color:#ffffff;font-size: 22px;justify-content: center;border-radius: 50%;margin-right: 10px;}
  .cardList .item .userInfo p{color: #A0ABC0;font-weight: normal;}
  .cardList .item:last-child{border-bottom-width: 0;}
  .cardList .item .btn{width: 100%;display: flex;align-items: center;justify-content: center;margin-top:10px;box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.084);}
  .cardList .item .btn img{width: 23px;height: 23px;margin-right: 20px;}
  .cardList .item:nth-child(1) .btn{background-color: #1877F2;}
  .cardList .item:nth-child(2) .btn{background-color: #ffffff;color: rgba(0, 0, 0, 0.54);}
  .cardList .item:nth-child(3) .btn{background-color: #4A5468;}
  .cardList .item:nth-child(4) .btn{background-color: #ffffff;color: rgba(0, 0, 0, 0.54);}
  .cardList .gray{background-color: rgba(0, 0, 0, 0.02);}
  @media (max-width: 768px) {
    .form .flex{grid-template-columns:1fr;gap: 20px;padding-bottom: 0;}
    .div{flex-direction: column;}
    .card{width: 100%;margin-left:0;}
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .form .flex{grid-template-columns:1fr;gap: 20px;padding-bottom: 0;}
    .card{width: 300px;}
  }
</style>
